import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function HomePrice({ onSectionNavigate }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleScrollTo = (id) => {
    if (location.pathname === '/') {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setIsMenuOpen(false);
      }
    } else {
      navigate('/');
      if (typeof onSectionNavigate === 'function') {
        onSectionNavigate(id);
      }
      setIsMenuOpen(false);
    }
  };

  return (
    <section class="mt-[40vh] bg-white px-4 md:mt-[50vh] md:px-8 lg:mt-[130vh] lg:px-36">
      <h1 className="mt-16 w-full border-l-4 border-midBlue pl-2 text-xl font-bold text-zinc-900 md:text-3xl lg:text-4xl">
        Tarifs
      </h1>

      <div class="max-w-screen-xl  ">
        <div class="mx-auto mb-8 max-w-screen-md text-center lg:mb-12"></div>
        <div class="space-y-8 sm:gap-6 lg:grid lg:grid-cols-3 lg:space-y-0 xl:gap-10">
          <div class="mx-auto flex max-w-lg flex-col rounded-lg border border-gray-100 bg-white p-6 text-center text-gray-900 shadow xl:p-8 dark:border-gray-600 dark:bg-darkBlue dark:text-white">
            <h3 class="mb-4 text-2xl font-semibold">Site vitrine</h3>
            <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
              Développé sous wordpress ou à la main selon votre choix
            </p>
            <div class="my-8 flex flex-wrap items-baseline justify-center">
              <span class="w-full text-gray-500 dark:text-gray-400">
                A partir de{' '}
              </span>
              <span class="mr-2 text-5xl font-extrabold">600€</span>
            </div>
            <ul role="list" class="mb-8 space-y-4 text-left">
              <li class="flex items-center space-x-3">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>1 à 5 pages</span>
              </li>
              <li class="flex items-center space-x-3">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>Site responsive</span>
              </li>
              <li class="flex items-center space-x-3">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>Formulaire de contact</span>
              </li>
              <li class="flex items-center space-x-3">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>Lien vers vos réseaux sociaux</span>
              </li>
              <li class="flex items-center space-x-3">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>Formation à l'utilisation du site</span>
              </li>
            </ul>
            <button
              href="#"
              class="rounded-lg bg-white px-5 py-2.5 text-center text-sm font-medium text-darkBlue hover:bg-midBlue hover:font-semibold"
              onClick={() => handleScrollTo('devis')}
            >
              Demander un devis
            </button>
          </div>
          <div class="mx-auto flex max-w-lg flex-col rounded-lg border border-gray-100 bg-white p-6 text-center text-gray-900 shadow xl:p-8 dark:border-gray-600 dark:bg-darkBlue dark:text-white">
            <h3 class="mb-4 text-2xl font-semibold">Site sur mesure</h3>
            <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
              E-commerce, site vitrine sur mesure, ...
            </p>
            <div class="my-8 flex flex-wrap items-baseline justify-center">
              <span class="w-full text-gray-500 dark:text-gray-400">
                A partir de{' '}
              </span>
              <span class="mr-2 text-5xl font-extrabold">900€</span>
            </div>
            <ul role="list" class="mb-8 space-y-4 text-left">
              <li class="flex items-center space-x-3">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>Nombre de pages illimités</span>
              </li>
              <li class="flex items-center space-x-3">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>Site responsive</span>
              </li>
              <li class="flex items-center space-x-3">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>Formumlaire de contact</span>
              </li>
              <li class="flex items-center space-x-3">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>Lien vers vos réseaux sociaux</span>
              </li>
              <li class="flex items-center space-x-3">
                <svg
                  class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>Formation à l'utilisation du site</span>
              </li>
            </ul>
            <button
              href="#"
              class="rounded-lg bg-white px-5 py-2.5 text-center text-sm font-medium text-darkBlue hover:bg-midBlue hover:font-semibold"
              onClick={() => handleScrollTo('devis')}
            >
              Demander un devis
            </button>
          </div>
          <div class="mx-auto flex max-w-lg flex-col rounded-lg border border-gray-100 bg-white p-6 text-center text-gray-900 shadow xl:p-8 dark:border-gray-600 dark:bg-darkBlue dark:text-white">
            <h3 class="mb-4 text-2xl font-semibold">Demande personnalisée</h3>
            <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
              Missions longues durées ou sites plus complexes
            </p>
            <div class="my-8 flex items-baseline justify-center">
              <span class="mr-2 text-5xl font-extrabold">250€</span>
              <span class="text-gray-500 dark:text-gray-400">/ jour</span>
            </div>
            <div className="flex h-full flex-col justify-between">
              <ul role="list" class="mb-8 space-y-4 text-left">
                <li class="flex items-center space-x-3">
                  <svg
                    class="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Réalisation selon votre demande</span>
                </li>
              </ul>
              <button
                href="#"
                class="rounded-lg bg-white px-5 py-2.5 text-center text-sm font-medium text-darkBlue hover:bg-midBlue hover:font-semibold"
                onClick={() => handleScrollTo('devis')}
              >
                Demander un devis
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomePrice;
